import * as React from 'react'
import { type HeadFC, graphql } from 'gatsby'

import Layout from '../components/layout'

const PrivacyPage = () => {
  return (
    <Layout>
      <section className="mx-auto flex min-h-screen max-w-2xl flex-col px-4 py-8 text-slate-800 sm:text-lg lg:text-xl">
        <h1 className="mb-8 text-3xl font-bold text-gray-800 sm:text-5xl">
          Política de Privacidad
        </h1>
        <p className="mb-12 text-xl text-gray-500 sm:text-2xl">
          Última actualización: 12 de septiembre de 2021
        </p>
        <h2 className="mb-10 text-xl font-bold text-gray-800 sm:text-2xl lg:text-3xl">
          1. Información al Usuario
        </h2>
        <p className="mb-8">
          IGLESIA EVANGÉLICA DE HERMANOS EN CALLE TRAFALGAR, 32 en adelante
          RESPONSABLE, es el Responsable del tratamiento de los datos personales
          del Usuario y le informa que estos datos serán tratados de conformidad
          con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril (GDPR)
          y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD), por lo que se le
          facilita la siguiente información del tratamiento:
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Fines del tratamiento
        </h3>
        <p className="mb-8">
          Actividades inherentes al desarrollo de la actividad realizada por
          IGLESIA EVANGÉLICA DE HERMANOS EN CALLE TRAFALGAR, 32. En el caso de
          que el Responsable del Tratamiento proyecte el tratamiento ulterior
          distinto, proporcionará al interesado, con anterioridad a dicho
          tratamiento ulterior, información sobre ese otro fin y cualquier
          información adicional. Los datos no serán tratados de manera
          incompatible con dichos fines; el tratamiento ulterior de los datos
          personales con fines de archivo en interés público, fines de
          investigación científica e histórica o fines estadísticos no se
          considerará incompatible con los fines iniciales.
        </p>
        <p className="mb-8">
          Los datos personales no serán utilizados para tratamientos
          automatizados para elaboración de perfiles ni se utilizarán para tomar
          ninguna decisión basada únicamente en el tratamiento automatizado,
          incluida la elaboración de perfiles.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Plazo de conservación
        </h3>
        <p className="mb-8">
          Los datos serán mantenidos durante el tiempo que sea necesario para
          los fines del tratamiento autorizados.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Legitimación
        </h3>
        <p className="mb-8">
          Consentimiento interesado y tratamiento necesario para llevar a cabo
          la gestión encomendada. Base jurídica del tratamiento: Art. 9.2.a y
          Art.6.1 a y b RGPD.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Destinatarios
        </h3>
        <ul className="mb-8 ml-10 list-disc">
          <li className="mb-4">
            <strong>Cesión de datos previstas: </strong>Solo se cederán los
            datos del interesado por obligación legal o por motivos relacionados
            con el servicio ofrecido por IGLESIA EVANGÉLICA DE HERMANOS EN CALLE
            TRAFALGAR, 32 mediante un contrato de Prestación de Servicios con el
            Encargado del Tratamiento.
          </li>
          <li className="mb-4">
            <strong>Transferencia a terceros países: </strong>No se prevé
            transferir datos personales a terceros países ni a organizaciones
            internacionales. En el caso de que el Responsable del Tratamiento
            prevea la transmisión de sus datos personales a terceros países
            deberá comunicarlo al interesado con anterioridad.
          </li>
        </ul>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Derechos
        </h3>
        <p className="mb-8">
          Derecho a retirar el consentimiento y derechos de acceso,
          rectificación, supresión, limitación, oposición y portabilidad de los
          datos.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Cómo ejercer sus derechos
        </h3>
        <p className="mb-8">
          Puede ejercer los derechos de acceso, rectificación, supresión,
          limitación, oposición y portabilidad de los datos, dirigiéndose al
          responsable del tratamiento en Calle Trafalgar, 32, Madrid, 28010,
          Madrid.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Derecho a retirar el consentimiento prestado
        </h3>
        <p className="mb-8">
          La retirada del consentimiento no afectará a la licitud del
          tratamiento basada en el consentimiento previo a su retirada.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Derecho a reclamar ante la autoridad de control
        </h3>
        <p className="mb-8">Correspondiente (AEPD).</p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Datos de contacto del DPO
        </h3>
        <p className="mb-8">
          AVDA BRASIL 17, 28020 MADRID -{' '}
          <a
            className="text-amber-500"
            href="mailto:dpd@sonorsolucionesnormativas.es"
          >
            dpd@sonorsolucionesnormativas.es
          </a>
          .
        </p>
        <h2 className="mb-10 text-xl font-bold text-gray-800 sm:text-2xl lg:text-3xl">
          2. Carácter obligatorio o facultativo de la información facilitada por
          el usuario
        </h2>
        <p className="mb-8">
          Los Usuarios, mediante la marcación de las casillas correspondientes y
          entrada de datos en los campos, marcados con un asterisco (*) en el
          formulario de contacto o presentados en formularios de descarga,
          aceptan expresamente y de forma libre e inequívoca, que sus datos son
          necesarios para atender su petición, por parte del prestador, siendo
          voluntaria la inclusión de datos en los campos restantes. El Usuario
          garantiza que los datos personales facilitados al RESPONSABLE son
          veraces y se hace responsable de comunicar cualquier modificación de
          los mismos.
        </p>
        <p className="mb-8">
          El RESPONSABLE informa y garantiza expresamente a los usuarios que sus
          datos personales no serán cedidos en ningún caso a terceros, y que
          siempre que realizara algún tipo de cesión de datos personales, se
          pedirá previamente el consentimiento expreso, informado e inequívoco
          por parte los Usuarios. Todos los datos solicitados a través del sitio
          web son obligatorios, ya que son necesarios para la prestación de un
          servicio óptimo al Usuario. En caso de que no sean facilitados todos
          los datos, no se garantiza que la información y servicios facilitados
          sean completamente ajustados a sus necesidades.
        </p>
        <h2 className="mb-10 text-xl font-bold text-gray-800 sm:text-2xl lg:text-3xl">
          3. Medidas de seguridad
        </h2>
        <p className="mb-8">
          Que de conformidad con lo dispuesto en las normativas vigentes en
          protección de datos personales, el RESPONSABLE está cumpliendo con
          todas las disposiciones de las normativas GDPR para el tratamiento de
          los datos personales de su responsabilidad, y manifiestamente con los
          principios descritos en el artículo 5 del GDPR, por los cuales son
          tratados de manera lícita, leal y transparente en relación con el
          interesado y adecuados, pertinentes y limitados a lo necesario en
          relación con los fines para los que son tratados.
        </p>
        <p className="mb-8">
          El RESPONSABLE garantiza que ha implementado políticas técnicas y
          organizativas apropiadas para aplicar las medidas de seguridad que
          establecen el GDPR con el fin de proteger los derechos y libertades de
          los Usuarios y les ha comunicado la información adecuada para que
          puedan ejercerlos.
        </p>
        <h2 className="mb-10 text-xl font-bold text-gray-800 sm:text-2xl lg:text-3xl">
          4. Política en Redes Sociales
        </h2>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Información al usuario
        </h3>
        <p className="mb-8">
          El RESPONSABLE, informa al USUARIO de que ha procedido a crear un
          perfil en las Redes Sociales Facebook, Instagram, Twitter, LinkedIn,
          Youtube, Vimeo y Google+, que es el responsable del tratamiento de los
          datos personales del usuario que se lleve a cabo en dichas redes
          sociales y le informa de que estos datos serán tratados de conformidad
          con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril
          (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), por lo
          que se le facilita la siguiente información del tratamiento:
        </p>
        <h4 className="mb-4 font-bold">
          ¿Para qué tratamos tus datos personales?
        </h4>
        <p className="mb-8">
          Fin del tratamiento: mantener una relación entre el USUARIO y el
          RESPONSABLE que puede incluir las siguientes operaciones:
        </p>
        <ul className="mb-8 ml-10 list-disc">
          <li className="mb-4">
            Tramitar solicitudes y consultas planteadas al responsable.
          </li>
          <li className="mb-4">
            Informar sobre actividades y eventos organizados por el responsable.
          </li>
          <li className="mb-4">
            Informar sobre productos o servicios ofrecidos por el responsable.
          </li>
          <li className="mb-4">
            Interactuar a través de los perfiles oficiales.
          </li>
        </ul>
        <h4 className="mb-4 font-bold">
          ¿Por qué motivo podemos tratar tus datos personales?
        </h4>
        <p className="mb-8">
          Base jurídica del tratamiento: artículo 6.1.a GDPR, el interesado ha
          dado su consentimiento para el tratamiento de sus datos personales
          para uno o varios fines específicos. El USUARIO dispone de un perfil
          en la misma red social y ha decidido unirse a la red social del
          RESPONSABLE mostrando así interés en la información que se publique en
          la misma, por tanto, en el momento de solicitar seguir nuestros
          perfiles oficiales, nos facilita su consentimiento para el tratamiento
          de aquellos datos personales publicados en su perfil.
        </p>
        <p className="mb-8">
          El USUARIO puede acceder en todo momento a las políticas de privacidad
          de la propia red social, así como configurar su perfil para garantizar
          su privacidad.
        </p>
        <p className="mb-8">
          El RESPONSABLE tiene acceso y trata aquella información pública del
          USUARIO, en especial, su nombre de contacto. Estos datos solo son
          utilizados dentro de la propia red social y únicamente se incorporarán
          a un fichero del RESPONSABLE cuando sea necesario para tramitar la
          petición del USUARIO.
        </p>
        <h4 className="mb-4 font-bold">
          ¿Durante cuánto tiempo guardaremos tus datos personales?
        </h4>
        <p className="mb-8">
          Criterios de conservación de los datos: se conservarán mientras el
          USUARIO no revoque el consentimiento prestado tal y como se indica en
          esta política de privacidad
        </p>
        <h4 className="mb-4 font-bold">
          ¿A quién facilitamos tus datos personales?
        </h4>
        <p className="mb-8">
          Comunicación de los datos: la información facilitada por el USUARIO a
          través de las redes sociales del RESPONSABLE, incluidos sus datos
          personales, puede ser publicada, siempre en función de los servicios
          que el USUARIO utilice, por lo que podrá quedar a disposición pública
          de otros terceros usuarios de las redes sociales. Desde el perfil de
          cada red social, el USUARIO puede configurar qué información quiere
          hacer pública en cada caso, ver los permisos que se han concedido,
          eliminarlos o desactivarlos, como cualquier aplicación de un tercero
          que ya no se desea utilizar.
        </p>
        <p className="mb-8">
          No está prevista ninguna comunicación de datos personales a terceros
          fuera de la red social salvo, si fuese imprescindible para el
          desarrollo y ejecución de las finalidades del tratamiento, a nuestros
          proveedores de servicios relacionados con comunicaciones, con los
          cuales el RESPONSABLE tiene suscritos los contratos de
          confidencialidad y de encargado de tratamiento exigidos por la
          normativa vigente de privacidad.
        </p>
        <h4 className="mb-4 font-bold">¿Cuáles son tus derechos?</h4>
        <p className="mb-8">
          Derechos que asisten al USUARIO: solo podrán satisfacerse en relación
          a aquella información que se encuentre bajo el control del
          RESPONSABLE.
        </p>
        <ul className="mb-8 ml-10 list-disc">
          <li className="mb-4">
            Derecho a retirar el consentimiento en cualquier momento.
          </li>
          <li className="mb-4">
            Derecho de acceso, rectificación, portabilidad y supresión de sus
            datos, y de limitación u oposición a su tratamiento.
          </li>
          <li className="mb-4">
            Derecho a presentar una reclamación ante la autoridad de control
            (www.aepd.es) si considera que el tratamiento no se ajusta a la
            normativa vigente.
          </li>
        </ul>
        <p className="mb-8">
          Datos de contacto para ejercer sus derechos: IGLESIA EVANGÉLICA DE
          HERMANOS EN CALLE TRAFALGAR, 32. Calle Trafalgar, 32 - 28010 Madrid
          (Madrid).
        </p>
        <p className="mb-8">
          Datos de contacto del delegado de protección de datos: AVDA BRASIL 17,
          28020 MADRID -{' '}
          <a
            className="text-amber-500"
            href="mailto:dpd@sonorsolucionesnormativas.es"
          >
            dpd@sonorsolucionesnormativas.es
          </a>
          .
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Utilización del perfil
        </h3>
        <p className="mb-8">
          El RESPONSABLE realizará las siguientes actuaciones:
        </p>
        <ul className="mb-8 ml-10 list-disc">
          <li className="mb-4">Acceso a la información pública del perfil.</li>
          <li className="mb-4">
            Publicación en el perfil del USUARIO de toda aquella información ya
            publicada en la red social del RESPONSABLE.
          </li>
          <li className="mb-4">
            Enviar mensajes personales e individuales a través de los canales de
            la red social.
          </li>
          <li className="mb-4">
            Actualizaciones del estado de la página que se publicarán en el
            perfil del USUARIO.
          </li>
        </ul>
        <p className="mb-8">
          El USUARIO siempre puede controlar sus conexiones, eliminar los
          contenidos que dejen de interesarle y restringir con quién comparte
          sus conexiones; para ello deberá acceder a su configuración de
          privacidad.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Publicaciones
        </h3>
        <p className="mb-8">
          El USUARIO, una vez sea seguidor o se haya unido a la red social del
          RESPONSABLE, podrá publicar en esta comentarios, enlaces, imágenes,
          fotografías o cualquier otro tipo de contenido multimedia soportado
          por la misma. El USUARIO, en todos los casos, debe ser el titular del
          contenido publicado, gozar de los derechos de autor y de propiedad
          intelectual o contar con el consentimiento de los terceros afectados.
        </p>
        <p className="mb-8">
          Se prohíbe expresamente cualquier publicación en la red social, ya
          sean textos, gráficos, fotografías, vídeos, etc. que atenten o sean
          susceptibles de atentar contra la moral, la ética, el buen gusto o el
          decoro, y/o que infrinjan, violen o quebranten los derechos de
          propiedad intelectual o industrial, el derecho a la imagen o la Ley.
        </p>
        <p className="mb-8">
          En estos casos, el RESPONSABLE se reserva el derecho a retirar de
          inmediato el contenido, sin comunicación previa, pudiendo solicitar el
          bloqueo permanente del USUARIO.
        </p>
        <p className="mb-8">
          El RESPONSABLE no se hará responsable de los contenidos que libremente
          ha publicado un USUARIO.
        </p>
        <p className="mb-8">
          El USUARIO debe tener presente que sus publicaciones serán conocidas
          por otros usuarios, por lo que él mismo es el principal responsable de
          su privacidad.
        </p>
        <p className="mb-8">
          Las imágenes que puedan publicarse en la red social no serán
          almacenadas en ningún fichero por parte del RESPONSABLE, pero sí que
          permanecerán en la red social.
        </p>
        <h3 className="mb-8 text-lg font-bold text-gray-800 sm:text-xl lg:text-2xl">
          Datos de menores de edad o personas con capacidades especiales
        </h3>
        <p className="mb-8">
          El acceso y registro a través de la redes sociales del RESPONSABLE
          está prohibido a menores de 18 años. Por su parte, si el USUARIO tiene
          capacidades especiales, será necesaria la intervención del titular de
          su patria potestad o tutela, o de su representante legal mediante
          documento válido que acredite la representación.
        </p>
        <p className="mb-8">
          El RESPONSABLE quedará expresamente exonerado de cualquier
          responsabilidad que pudiera derivarse del uso de las redes sociales
          por parte de menores o personas con capacidades especiales. Las redes
          sociales del RESPONSABLE no recogen conscientemente ninguna
          información personal de menores de edad, por ello, si el USUARIO es
          menor de edad, no debe registrarse, ni utilizar las redes sociales del
          RESPONSABLE ni tampoco proporcionar ninguna información personal.
        </p>
      </section>
    </Layout>
  )
}

export const Head: HeadFC = () => <title>Política de Privacidad</title>
export default PrivacyPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
